import React from 'react'
import styles from './index.module.scss'
import classnames from 'classnames'

export default function Spinner({ size = 40, className = 'text-white' }) {
  return (
    <svg
      className={classnames(styles.Spinner, className)}
      width={size}
      height={size}
      viewBox="22 22 44 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={styles.Path} fill="none" strokeWidth="3" strokeLinecap="round" cx="44" cy="44" r="20.2" />
    </svg>
  )
}
